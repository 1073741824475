import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Phone, MessageSquare, LineChart, Users, Briefcase, Zap, ChevronRight, Star, CheckCircle, XCircle, Heart, Clock, TrendingUp } from 'lucide-react';
import AnimatedRocketLaunch from '../components/AnimatedRocketLaunch';
import StarryBackground from '../components/StarryBackground';
import ErrorBoundary from '../components/ErrorBoundary';

interface FeatureCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, icon }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "0px 0px -100px 0px" }} // Improves scroll timing
      transition={{ duration: 0.4, ease: "easeOut" }} // Consistent timing
      whileHover={{ 
        scale: 1.02, 
        backgroundColor: 'rgba(237, 151, 76, 0.05)',
        transition: { duration: 0.2 } // Faster hover for better responsiveness
      }}
      className="p-6 sm:p-8 bg-gray-800/30 backdrop-blur-sm rounded-xl border border-gray-700/50 transition-all shadow-lg"
      style={{ 
        willChange: "transform", // Hardware acceleration
        backfaceVisibility: "hidden"
      }}
    >
      <div className="flex items-center mb-3">
        <div className="w-10 h-10 bg-secondary/20 rounded-lg flex items-center justify-center text-secondary mr-3 flex-shrink-0">
          {icon}
        </div>
        <h3 className="text-lg sm:text-xl font-main font-semibold text-white">{title}</h3>
      </div>
      <p className="text-gray-300 text-sm leading-relaxed">{description}</p>
    </motion.div>
  );
};

const TestimonialCard: React.FC<{ name: string, role: string, content: string }> = ({ name, role, content }) => {
  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true, margin: "0px 0px -100px 0px" }}
      transition={{ duration: 0.4, ease: "easeOut" }}
      className="bg-gray-800/40 backdrop-blur-sm p-6 rounded-xl border border-gray-700/50 shadow-lg"
      style={{ 
        willChange: "transform, opacity",
        backfaceVisibility: "hidden"
      }}
    >
      <div className="flex items-center mb-4">
        {[1, 2, 3, 4, 5].map(star => (
          <Star key={star} size={18} className="text-secondary fill-secondary mr-1" />
        ))}
      </div>
      <p className="text-gray-300 italic mb-4 text-sm">"{content}"</p>
      <div className="flex items-center">
        <div className="w-10 h-10 rounded-full bg-secondary/30 flex items-center justify-center text-lg font-bold text-secondary">
          {name.charAt(0)}
        </div>
        <div className="ml-3">
          <p className="text-white font-semibold">{name}</p>
          <p className="text-gray-400 text-xs">{role}</p>
        </div>
      </div>
    </motion.div>
  );
};

// Simplified Pricing Comparison Component
const PricingComparisonSection: React.FC = () => {
  const features = [
    {
      name: "CRM & PIPELINE MANAGEMENT",
      replacedTools: [
        { name: "Salesforce", icon: "" }
      ],
      extraInfo: "(3 users)",
      monthlyPrice: 990,
    },
    {
      name: "UNLIMITED SALES FUNNELS",
      replacedTools: [
        { name: "ClickFunnels", icon: "" },
        { name: "Unbounce", icon: "" }
      ],
      monthlyPrice: 297,
    },
    {
      name: "WEBSITE BUILDER",
      replacedTools: [
        { name: "WordPress", icon: "" },
        { name: "Wix", icon: "" },
        { name: "Squarespace", icon: "" }
      ],
      monthlyPrice: 29,
    },
    {
      name: "SURVEYS & FORMS",
      replacedTools: [
        { name: "SurveyMonkey", icon: "" },
        { name: "Typeform", icon: "" },
        { name: "JotForm", icon: "" },
        { name: "Google Forms", icon: "" }
      ],
      monthlyPrice: 49,
    },
    {
      name: "EMAIL MARKETING",
      replacedTools: [
        { name: "Mailchimp", icon: "" },
        { name: "ActiveCampaign", icon: "" },
        { name: "HubSpot", icon: "" },
        { name: "Constant Contact", icon: "" }
      ],
      monthlyPrice: 99,
    },
    {
      name: "2-WAY SMS MARKETING",
      replacedTools: [
        { name: "Twilio", icon: "" },
        { name: "EZ Texting", icon: "" },
        { name: "SimpleTexting", icon: "" }
      ],
      monthlyPrice: 99,
    },
    {
      name: "BOOKING & APPOINTMENTS",
      replacedTools: [
        { name: "Calendly", icon: "" },
        { name: "Acuity", icon: "" },
        { name: "Square", icon: "" }
      ],
      monthlyPrice: 29,
    },
    {
      name: "WORKFLOW AUTOMATIONS",
      replacedTools: [
        { name: "Zapier", icon: "" },
        { name: "HubSpot", icon: "" },
        { name: "Keap", icon: "" }
      ],
      monthlyPrice: 169,
    },
    {
      name: "COURSES & PRODUCTS",
      replacedTools: [
        { name: "Kajabi", icon: "" },
        { name: "Teachable", icon: "" }
      ],
      monthlyPrice: 99,
    },
    {
      name: "CALL TRACKING",
      replacedTools: [
        { name: "CallRail", icon: "" },
        { name: "Twilio", icon: "" }
      ],
      monthlyPrice: 49,
    },
    {
      name: "REPUTATION MANAGEMENT",
      replacedTools: [
        { name: "BirdEye", icon: "" },
        { name: "Podium", icon: "" },
        { name: "GatherUp", icon: "" }
      ],
      monthlyPrice: 159,
    },
    {
      name: "TRACKING & ANALYTICS",
      replacedTools: [
        { name: "Google Analytics", icon: "" }
      ],
      monthlyPrice: 299,
    },
    {
      name: "TEXT ORDERING",
      replacedTools: [
        { name: "Prokeep", icon: "" }
      ],
      monthlyPrice: 250,
    },
    {
      name: "DOCUMENT SIGNING",
      replacedTools: [
        { name: "DocuSign", icon: "" },
        { name: "HelloSign", icon: "" }
      ],
      monthlyPrice: 47,
    },
    {
      name: "MOBILE APP",
      replacedTools: [],
      monthlyPrice: 0,
      unique: true,
      exclusiveFeature: true
    }
  ];

  // Optional add-on
  const optionalAddOn = {
    name: "WHITE LABELED DESKTOP APP",
    replacedTools: [],
    monthlyPrice: 499,
    oneTimeFee: 5000,
    optional: true
  };

  // Calculate the total price of individual tools
  const totalIndividualPrice = 8163;
  
  // Your platform price
  const actualPlatformPrice = 897;

  return (
    <div id="pricing" className="py-24 relative z-10">
      {/* Decorative background elements */}
      <div className="absolute top-10 left-1/5 w-1 h-1 bg-white rounded-full opacity-25"></div>
      <div className="absolute bottom-20 right-1/4 w-1 h-1 bg-white rounded-full opacity-20"></div>
      <div className="absolute top-1/3 right-1/6 w-1 h-1 bg-white rounded-full opacity-30"></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-main font-bold text-white">
            Complete Paint Store Platform
          </h2>
          <div className="mt-3 h-1 w-24 bg-secondary mx-auto rounded-full"></div>
          <p className="mt-6 text-lg text-gray-300 max-w-3xl mx-auto">
            Replace multiple apps with one simple solution built for paint stores
          </p>
        </div>

        {/* Main Content - Desktop and mobile - Improved sticky approach */}
        <div className="flex flex-col lg:flex-row gap-8 mb-12" style={{ minHeight: "1000px" }}>
          {/* Left Side - Price Card - Fixed position on scroll */}
          <div className="w-full lg:w-1/3 relative">
            <div className="lg:sticky lg:top-6 sticky-pricing" style={{ position: "sticky" }}>
              <div className="bg-gray-800/40 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 shadow-xl">
                <h3 className="text-2xl font-main font-bold mb-6 text-white">LeadLaunch Pro</h3>
                
                <div className="mb-8">
                  <div className="flex flex-col">
                    <span className="text-gray-400 text-sm mb-1">Starting at</span>
                    <div className="flex items-baseline">
                      <span className="text-5xl font-main font-bold text-white">${actualPlatformPrice}</span>
                      <span className="text-gray-400 ml-2">/month</span>
                    </div>
                  </div>
                  <p className="mt-4 text-gray-300">
                    Everything your paint store needs to modernize, streamline operations, and boost sales.
                  </p>
                </div>
                
                <div className="space-y-5 mb-8">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 text-secondary">
                      <CheckCircle className="h-6 w-6" />
                    </div>
                    <div className="ml-3">
                      <h4 className="text-lg font-semibold text-white">All Features Included</h4>
                      <p className="text-gray-400">No hidden fees or add-ons needed</p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="flex-shrink-0 text-secondary">
                      <CheckCircle className="h-6 w-6" />
                    </div>
                    <div className="ml-3">
                      <h4 className="text-lg font-semibold text-white">Seamless Integration</h4>
                      <p className="text-gray-400">All tools work perfectly together</p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="flex-shrink-0 text-secondary">
                      <CheckCircle className="h-6 w-6" />
                    </div>
                    <div className="ml-3">
                      <h4 className="text-lg font-semibold text-white">Dedicated Support</h4>
                      <p className="text-gray-400">Expert help when you need it</p>
                    </div>
                  </div>
                </div>
                
                <a
                  href="https://crm.tryleadlaunch.com/signup"
                  className="block w-full py-3 px-8 text-center bg-secondary hover:bg-secondary/90 text-dark text-lg font-medium rounded-lg transition-all duration-300 font-main transform hover:-translate-y-1"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          
          {/* Right Side - Pricing Table (Matching deployed design) */}
          <div className="w-full lg:w-2/3">
            <div className="bg-gray-800/40 backdrop-blur-sm rounded-xl border border-gray-700/50 shadow-xl overflow-hidden">
              {/* Table Headers */}
              <div className="grid grid-cols-3 bg-gray-700/30 text-xs sm:text-sm py-3 sm:py-4 px-4 sm:px-6">
                <div className="col-span-1 font-main font-semibold text-white">INCLUDED FEATURES</div>
                <div className="col-span-1 font-main font-semibold text-white text-center">TOOLS YOU CAN REPLACE</div>
                <div className="col-span-1 font-main font-semibold text-right text-white">THEIR MONTHLY COST</div>
              </div>
              
              {/* Table Rows - Simplified for performance */}
              <div className="divide-y divide-gray-700/30">
                {features.map((feature, index) => (
                  <div 
                    key={index}
                    className="grid grid-cols-3 py-4 px-4 sm:px-6 items-start sm:items-center hover:bg-gray-700/20 transition-colors"
                  >
                    {/* Feature Name */}
                    <div className="col-span-1 flex items-start sm:items-center gap-2 sm:gap-3 text-xs sm:text-sm md:text-base">
                      <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 flex-shrink-0 text-secondary mt-0.5 sm:mt-0" />
                      <div>
                        <span className="font-medium text-white">{feature.name}</span>
                        {feature.extraInfo && (
                          <span className="block text-[10px] sm:text-xs text-gray-400 mt-0.5">{feature.extraInfo}</span>
                        )}
                      </div>
                    </div>
                    
                    {/* Replaced Tools - Centered */}
                    <div className="col-span-1 text-gray-300 text-xs md:text-sm flex justify-center">
                      {feature.replacedTools.length > 0 ? (
                        <div className="flex flex-wrap justify-center gap-1">
                          {feature.replacedTools.map((tool, idx) => (
                            <span key={idx} className="inline-flex px-1.5 sm:px-2 py-0.5 sm:py-1 bg-gray-700/30 rounded-md text-[10px] sm:text-xs whitespace-nowrap">
                              {tool.name}
                            </span>
                          ))}
                        </div>
                      ) : feature.unique ? (
                        <span className="text-[10px] sm:text-xs italic text-gray-400">
                          Not available with other tools
                        </span>
                      ) : null}
                    </div>
                    
                    {/* Price */}
                    <div className="col-span-1 text-right">
                      {feature.monthlyPrice > 0 ? (
                        <span className="font-semibold text-white text-xs sm:text-sm md:text-base">
                          ${feature.monthlyPrice}
                          <span className="hidden md:inline">/MONTHLY</span>
                        </span>
                      ) : feature.unique ? (
                        <span className={`text-[10px] sm:text-xs px-1.5 sm:px-2 py-0.5 sm:py-1 rounded-md ${feature.exclusiveFeature ? "bg-primary/30 text-secondary" : "bg-secondary/20 text-secondary"}`}>
                          {feature.exclusiveFeature ? "LEAD LAUNCH EXCLUSIVE" : "INCLUDED"}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ))}
                
                {/* Optional Add-On */}
                <div className="grid grid-cols-3 py-4 px-4 sm:px-6 items-start sm:items-center hover:bg-gray-700/20 transition-colors">
                  <div className="col-span-1">
                    <span className="font-medium text-white text-xs sm:text-sm md:text-base">{optionalAddOn.name}</span>
                  </div>
                  
                  <div className="col-span-1"></div>
                  
                  <div className="col-span-1 text-right">
                    <span className="font-semibold text-white whitespace-nowrap text-[10px] sm:text-xs md:text-sm">
                      ${optionalAddOn.oneTimeFee} + ${optionalAddOn.monthlyPrice}
                      <span className="hidden md:inline">/MONTHLY</span>
                    </span>
                  </div>
                </div>
              </div>
              
              {/* Footer - Total Cost */}
              <div className="grid grid-cols-3 py-4 sm:py-5 px-4 sm:px-6 bg-gray-700/40 items-center">
                <div className="col-span-2 font-main font-bold text-sm sm:text-base md:text-lg text-white">COST OF ALL THESE TOOLS SEPARATELY</div>
                <div className="col-span-1 text-right font-bold text-red-400 text-xs sm:text-base">
                  ${totalIndividualPrice}/month
                </div>
              </div>
              
              {/* Lead Launch Price */}
              <div className="grid grid-cols-3 py-4 sm:py-5 px-4 sm:px-6 bg-gray-800/70 items-center">
                <div className="col-span-2 font-main font-bold text-sm sm:text-base md:text-lg text-white">YOUR COST WITH LEAD LAUNCH</div>
                <div className="col-span-1 text-right">
                  <motion.div 
                    className="inline-block font-bold text-green-400 text-sm sm:text-base md:text-lg px-2 py-1 rounded-lg"
                    initial={{ scale: 1 }}
                    animate={{ 
                      scale: [1, 1.05, 1],
                      textShadow: [
                        "0 0 5px rgba(74, 222, 128, 0.3)",
                        "0 0 15px rgba(74, 222, 128, 0.6)",
                        "0 0 5px rgba(74, 222, 128, 0.3)"
                      ],
                      boxShadow: [
                        "0 0 5px rgba(74, 222, 128, 0.2)",
                        "0 0 12px rgba(74, 222, 128, 0.4)",
                        "0 0 5px rgba(74, 222, 128, 0.2)"
                      ]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    Starting at ${actualPlatformPrice}/month
                  </motion.div>
                </div>
              </div>
              
              {/* Savings Summary */}
              <div className="py-5 sm:py-6 px-4 sm:px-6 bg-gradient-to-r from-primary/30 to-primary/10">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                  <div className="font-main text-base sm:text-lg md:text-xl mb-4 sm:mb-0 text-white text-center sm:text-left">
                    <span className="font-bold">Your monthly savings:</span> 
                    <motion.span 
                      className="ml-2 text-green-400 font-bold"
                      initial={{ opacity: 1 }}
                      animate={{ 
                        opacity: [1, 0.9, 1],
                        textShadow: [
                          "0 0 5px rgba(74, 222, 128, 0.2)",
                          "0 0 10px rgba(74, 222, 128, 0.3)",
                          "0 0 5px rgba(74, 222, 128, 0.2)"
                        ]
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse"
                      }}
                    >
                      ${totalIndividualPrice - actualPlatformPrice}/month
                    </motion.span>
                  </div>
                  <a
                    href="https://crm.tryleadlaunch.com/signup"
                    className="px-6 py-2.5 bg-secondary hover:bg-secondary/90 text-dark font-medium rounded-lg transition-all duration-300 text-base font-main whitespace-nowrap"
                  >
                    Start Saving Today
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Bottom Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <div className="bg-gray-800/30 backdrop-blur-sm p-6 rounded-xl border border-gray-700/50 shadow-lg">
            <h3 className="text-xl font-main font-semibold mb-4 text-white">Lower Total Cost</h3>
            <p className="text-gray-300">
              Save over $7,200 per month compared to purchasing each tool separately. Consolidate your paint store technology and reduce expenses.
            </p>
          </div>
          
          <div className="bg-gray-800/30 backdrop-blur-sm p-6 rounded-xl border border-gray-700/50 shadow-lg">
            <h3 className="text-xl font-main font-semibold mb-4 text-white">Mobile Task Management</h3>
            <p className="text-gray-300">
              Empower your staff with the mobile app where you can assign tasks, send updates, track order status, and manage customer follow-ups from anywhere.
            </p>
          </div>
          
          <div className="bg-gray-800/30 backdrop-blur-sm p-6 rounded-xl border border-gray-700/50 shadow-lg">
            <h3 className="text-xl font-main font-semibold mb-4 text-white">Improved Customer Experience</h3>
            <p className="text-gray-300">
              Delight customers with modern text ordering, seamless color consultations, automated appointment reminders, and consistent follow-up.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const features = [
    {
      title: "Call Tracking & Recording",
      description: "Reference calls for order accuracy, share recordings with staff, and review calls later to ensure correct paint colors and order details.",
      icon: <Phone size={22} />
    },
    {
      title: "Text Ordering System",
      description: "Receive paint orders and photos via text to a dedicated business number, eliminating the need for staff to share personal contact information.",
      icon: <MessageSquare size={22} />
    },
    {
      title: "Unlimited User Profiles",
      description: "Create profiles for every employee, assign tasks through the app, and enhance communication between in-store staff and outside sales reps.",
      icon: <Users size={22} />
    },
    {
      title: "Smart Automation",
      description: "Automated follow-up with customers, intelligent phone routing when short-staffed, and schedule color consultations automatically.",
      icon: <Zap size={22} />
    },
    {
      title: "Customer Retention Tools",
      description: "Tag high-value contractor accounts and alert sales reps when professional painters or key customers haven't made purchases in customizable timeframes.",
      icon: <LineChart size={22} />
    },
    {
      title: "Custom Calendars & Bookings",
      description: "Create specialized booking systems for in-store and in-home color consultations, flooring measurements, and window treatment appointments.",
      icon: <Briefcase size={22} />
    }
  ];

  const testimonials = [
    {
      name: "Luke Loveday",
      role: "All Pro Member",
      content: "The call recording feature eliminated our order mistakes by 90%. When customers claim we mixed the wrong color, we can verify exactly what was requested."
    },
    {
      name: "Jim Praytor",
      role: "All Pro Member",
      content: "Our contractors love sending orders by text with photos of color chips. The CRM helps us track preferences and maintain strong relationships with our professional customers."
    },
    {
      name: "Monica Davis",
      role: "Store Owner",
      content: "The booking system has transformed how we schedule color consultations. Our staff calendar is now optimized and customers love the automatic text reminders before appointments."
    }
  ];

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 font-body overflow-hidden">
      <StarryBackground />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
              <div className="text-center">
                <ErrorBoundary>
                  <AnimatedRocketLaunch />
                </ErrorBoundary>

                <motion.h1
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="text-4xl tracking-tight font-main font-bold sm:text-5xl md:text-6xl lg:text-7xl"
                >
                  <span className="block text-white">Launch Your Paint Store</span>
                  <span className="block text-primary mt-2">to New Heights</span>
                </motion.h1>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  className="mt-6 max-w-lg mx-auto text-lg text-gray-300 sm:max-w-2xl"
                >
                  The <span className="font-bold text-secondary">All-in-One CRM</span> designed to boost customer service 
                  and drive contractor acquisitions with powerful features like text-to-order, 
                  AI-driven customer follow-up, call recording, and seamless counter-to-field 
                  sales team integration.
                </motion.p>
                
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                  className="mt-10 max-w-md mx-auto sm:flex sm:justify-center gap-4"
                >
                  <a
                    href="https://crm.tryleadlaunch.com/signup"
                    className="w-3/4 mx-auto sm:w-auto flex items-center justify-center px-8 py-3.5 text-lg font-medium rounded-xl text-dark bg-secondary hover:bg-secondary/90 transition-all duration-300 shadow-lg shadow-secondary/20 font-main transform hover:-translate-y-1"
                  >
                    Get Started
                  </a>
                </motion.div>
                
                {/* New Value Highlight Icons */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1, duration: 0.5 }}
                  className="mt-12 flex flex-wrap justify-center items-center gap-x-10 gap-y-4 text-gray-300 text-sm mx-auto max-w-3xl"
                >
                  <div className="flex items-center">
                    <TrendingUp size={16} className="mr-2 text-secondary" />
                    <span>Boost Sales Revenue</span>
                  </div>
                  <div className="flex items-center">
                    <Clock size={16} className="mr-2 text-secondary" />
                    <span>Streamline Store Operations</span>
                  </div>
                  <div className="flex items-center">
                    <Heart size={16} className="mr-2 text-secondary" />
                    <span>Elevate Customer Service</span>
                  </div>
                </motion.div>
              </div>
            </main>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-28 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-primary/5 via-primary/10 to-transparent opacity-30" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-16">
            <h2 className="text-3xl sm:text-4xl font-main font-bold text-white">
              Streamline Your Paint Store
            </h2>
            <div className="mt-4 h-1 w-20 bg-secondary mx-auto rounded-full"></div>
            <p className="mt-6 text-lg text-gray-300 max-w-2xl mx-auto">
              Specialized tools designed for paint retailers to improve operations, boost customer retention, and increase sales
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
              />
            ))}
          </div>
        </div>
      </div>
      
      {/* Updated Pricing Comparison Section */}
      <PricingComparisonSection />
      
      {/* Testimonials Section */}
      <div className="py-28 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900/0 via-gray-800/50 to-gray-900/0" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-16">
            <h2 className="text-3xl sm:text-4xl font-main font-bold text-white">
              What Our Customers Say
            </h2>
            <div className="mt-4 h-1 w-20 bg-secondary mx-auto rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                name={testimonial.name}
                role={testimonial.role}
                content={testimonial.content}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="py-28 pb-20 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="relative py-16 px-8 rounded-3xl overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-primary/30 to-primary/10 backdrop-blur-md rounded-3xl border border-primary/20" />
            <div className="relative text-center">
              <h3 className="text-3xl font-main font-bold text-white mb-6">
                Ready to Modernize Your Paint Store?
              </h3>
              <p className="text-lg text-gray-200 mb-8 max-w-2xl mx-auto">
                Join paint retailers who have improved contractor relationships, customer satisfaction, eliminated ordering errors,
                and boosted repeat business with LeadLaunch's specialized platform.
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4 items-center">
                <a
                  href="https://crm.tryleadlaunch.com/signup"
                  className="px-8 py-3.5 text-lg font-medium rounded-xl text-dark bg-secondary hover:bg-secondary/90 transition-all duration-300 shadow-lg transform hover:-translate-y-1 font-main"
                >
                  Get Started
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

    </div>
  );
};

export default Home;