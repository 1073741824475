import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, ChevronDown, Rocket, PaintBucket, MessageSquare, Star } from 'lucide-react';

const Navbar = () => {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  // Track scroll position for navbar style changes
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close mobile menu on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 120, damping: 20 }}
      className={`${isScrolled ? 'bg-gray-900/95 backdrop-blur-md shadow-xl' : 'bg-transparent'} 
        fixed w-full z-50 transition-all duration-300`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 md:h-20 items-center">
          {/* Logo */}
          <div className="flex">
            <Link to="/" className="flex-shrink-0 flex items-center group">
              <motion.img
                src="/images/logo.png"
                alt="LeadLaunch"
                className="h-10 w-auto"
                whileHover={{ rotate: 10, scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 10 }}
              />
              <motion.div
                initial={{ width: 0, opacity: 0 }}
                animate={{ width: 'auto', opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="ml-3 overflow-hidden"
              >
                <span className="text-2xl font-main text-white">
                  Lead<span className="text-secondary font-bold">Launch</span>
                </span>
              </motion.div>
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {/* Features dropdown temporarily disabled
            <div className="relative">
              <button 
                className="text-gray-300 hover:text-white flex items-center px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <span>Features</span>
                <ChevronDown size={16} className={`ml-1 transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} />
              </button>
              
              <AnimatePresence>
                {dropdownOpen && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute left-0 mt-2 w-60 bg-gray-800 border border-gray-700 rounded-lg shadow-xl z-50"
                  >
                    <div className="p-2 space-y-1">
                      <FeatureItem icon={<PaintBucket size={16} />} title="Paint Store CRM" description="Custom tools for paint retailers" />
                      <FeatureItem icon={<MessageSquare size={16} />} title="Text Ordering" description="Accept orders via text message" />
                      <FeatureItem icon={<Star size={16} />} title="Contractor Tools" description="Specialized features for pros" />
                      <FeatureItem icon={<Rocket size={16} />} title="All Features" description="See everything we offer" />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            */}
            
            <Link 
              to="/" 
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              onClick={(e) => {
                e.preventDefault();
                
                // Use setTimeout to ensure consistent behavior between mobile and desktop
                setTimeout(() => {
                  // Navigate to home if not already there
                  if (location.pathname !== '/') {
                    window.location.href = '/#pricing';
                  } else {
                    // If already on homepage, just scroll to pricing
                    document.getElementById('pricing')?.scrollIntoView({ behavior: 'smooth' });
                  }
                }, 50); // Smaller delay for desktop since no menu to close
              }}
            >
              Pricing
            </Link>
            
            {user ? (
              <>
                <Link to="/dashboard" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Dashboard
                </Link>
                <button onClick={logout} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Logout
                </button>
              </>
            ) : (
              <>
                <a 
                  href="https://app.tryleadlaunch.com" 
                  className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Login
                </a>
                <motion.a 
                  href="https://crm.tryleadlaunch.com/signup" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-secondary hover:bg-secondary/90 text-gray-900 px-4 py-2 rounded-md text-sm font-medium shadow-lg"
                  whileHover={{ 
                    scale: 1.05,
                    boxShadow: "0 0 15px rgba(237, 151, 76, 0.5)"
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                </motion.a>
              </>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button 
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-300 hover:text-white transition-colors"
              aria-label="Toggle menu"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden bg-gray-900 border-t border-gray-800"
          >
            <div className="px-4 py-3 space-y-3">
              {/* Features section temporarily disabled 
              <div className="py-3 border-b border-gray-800">
                <span className="text-white font-semibold mb-1 block">Features</span>
                <div className="pl-4 space-y-2 mt-2 text-sm">
                  <Link to="/features/paint-store-crm" className="text-gray-300 hover:text-white block py-1">
                    Paint Store CRM
                  </Link>
                  <Link to="/features/text-ordering" className="text-gray-300 hover:text-white block py-1">
                    Text Ordering
                  </Link>
                  <Link to="/features/contractor-tools" className="text-gray-300 hover:text-white block py-1">
                    Contractor Tools
                  </Link>
                  <Link to="/features" className="text-gray-300 hover:text-white block py-1">
                    All Features
                  </Link>
                </div>
              </div>
              */}
              
              <Link 
                to="/" 
                className="text-gray-300 hover:text-white block py-2"
                onClick={(e) => {
                  // Always prevent default since we're handling navigation
                  e.preventDefault();
                  
                  // First close the menu
                  setIsOpen(false);
                  
                  // Use setTimeout to ensure menu close happens before navigation
                  setTimeout(() => {
                    // Navigate to home if not already there
                    if (location.pathname !== '/') {
                      window.location.href = '/#pricing';
                    } else {
                      // If already on homepage, just scroll to pricing
                      document.getElementById('pricing')?.scrollIntoView({ behavior: 'smooth' });
                    }
                  }, 100); // Small delay to allow menu state update
                }}
              >
                Pricing
              </Link>
              
              {user ? (
                <>
                  <Link to="/dashboard" className="text-gray-300 hover:text-white block py-2">
                    Dashboard
                  </Link>
                  <button onClick={logout} className="text-gray-300 hover:text-white block py-2 w-full text-left">
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <a 
                    href="https://app.tryleadlaunch.com" 
                    className="text-gray-300 hover:text-white block py-2"
                  >
                    Login
                  </a>
                  <a 
                    href="https://crm.tryleadlaunch.com/signup" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="bg-secondary text-gray-900 px-4 py-2 rounded-md inline-block mt-2 font-medium shadow-md"
                  >
                    Get Started
                  </a>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

// Individual feature item for dropdown
interface FeatureItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, title, description }) => (
  <a 
    href="#" 
    className="flex items-start p-3 rounded-md hover:bg-gray-700/50 transition-colors group"
  >
    <div className="text-secondary group-hover:text-white transition-colors mt-0.5">
      {icon}
    </div>
    <div className="ml-3">
      <p className="text-white font-medium">{title}</p>
      <p className="text-gray-400 text-xs">{description}</p>
    </div>
  </a>
);

export default Navbar;