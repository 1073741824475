import React from 'react';

const Login = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-main font-bold">Login</h1>
    </div>
  );
};

export default Login;
