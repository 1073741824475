import React from 'react';
import StarryBackground from '../components/StarryBackground';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-gray-300">
      <StarryBackground />
      
      <div className="container mx-auto px-4 pt-32 pb-12 relative z-10">
        <div className="max-w-4xl mx-auto bg-gray-800 bg-opacity-80 p-8 rounded-lg shadow-lg border border-gray-700">
          <h1 className="text-4xl font-bold mb-8 text-white border-b border-orange-500 pb-2 inline-block">Terms and Conditions</h1>
          
          <div className="space-y-6">
            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">1. Introduction</h2>
              <p className="mb-4">
                Welcome to Lead Launch LLC ("Company", "we", "our", "us")! As you have navigated to our Terms and Conditions, 
                you are either curious about our legal framework or preparing to use our platform. These Terms and Conditions 
                govern your use of our website located at <span className="text-blue-400">tryleadlaunch.com</span> and our 
                CRM platform (together or individually "Service") operated by Lead Launch LLC.
              </p>
              <p>
                By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the 
                terms, you may not access the Service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">2. Subscriptions</h2>
              <p className="mb-4">
                Some parts of the Service are billed on a subscription basis. You will be billed in advance on a recurring and 
                periodic basis ("Billing Cycle"). Billing cycles are set on a monthly basis.
              </p>
              <p className="mb-4">
                At the end of each Billing Cycle, your subscription will automatically renew under the exact same conditions 
                unless you cancel it or Lead Launch LLC cancels it. You may cancel your subscription either through your online 
                account or by contacting Lead Launch LLC's customer support team.
              </p>
              <p>
                A valid payment method, including credit card, is required to process the payment for your subscription. 
                You shall provide Lead Launch LLC with accurate and complete billing information.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">3. Use of Service</h2>
              <p className="mb-4">
                Our Service is designed to be used by paint stores to manage customer relationships, track leads, and streamline 
                operations. You are responsible for maintaining the security of your account and password. Lead Launch LLC cannot 
                and will not be liable for any loss or damage from your failure to comply with this security obligation.
              </p>
              <p className="mb-4">
                You are responsible for all content posted and activity that occurs under your account. You may not use the 
                Service for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in 
                your jurisdiction.
              </p>
              <p>
                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service without the 
                express written permission by Lead Launch LLC.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">4. Intellectual Property</h2>
              <p className="mb-4">
                The Service and its original content, features and functionality are and will remain the exclusive property of 
                Lead Launch LLC and its licensors. The Service is protected by copyright, trademark, and other laws of both the 
                United States and foreign countries. Our trademarks and trade dress may not be used in connection with any 
                product or service without the prior written consent of Lead Launch LLC.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">5. Limitation of Liability</h2>
              <p className="mb-4">
                In no event shall Lead Launch LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, 
                be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, 
                loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of 
                or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; 
                (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions 
                or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, 
                whether or not we have been informed of the possibility of such damage.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">6. Disclaimer</h2>
              <p className="mb-4">
                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. 
                The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, 
                implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
              </p>
              <p>
                Lead Launch LLC does not warrant that a) the Service will function uninterrupted, secure or available at any 
                particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or 
                other harmful components; or d) the results of using the Service will meet your requirements.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">7. Governing Law</h2>
              <p>
                These Terms shall be governed and construed in accordance with the laws of the United States, without regard 
                to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be 
                considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by 
                a court, the remaining provisions of these Terms will remain in effect.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">8. Changes to Terms</h2>
              <p className="mb-4">
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is 
                material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes 
                a material change will be determined at our sole discretion.
              </p>
              <p>
                By continuing to access or use our Service after those revisions become effective, you agree to be bound by the 
                revised terms. If you do not agree to the new terms, please stop using the Service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">9. Data Backup</h2>
              <p className="mb-4">
                While we implement industry-standard data backup procedures, you are ultimately responsible for maintaining your 
                own backup of your data. Lead Launch LLC will not be responsible for loss of any data.
              </p>
              <p>
                <span className="text-blue-400">🚀 Just like a successful paint job requires proper preparation, a successful 
                business requires proper data management.</span>
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">10. Contact Us</h2>
              <p>
                If you have any questions about these Terms, please contact us at <span className="text-blue-400">support@tryleadlaunch.com</span>.
              </p>
            </section>
          </div>

          <div className="mt-10 text-sm text-gray-400 text-center">
            <p>Last updated: March 16, 2025</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;