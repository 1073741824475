import React, { useMemo, useState, useEffect } from "react";
import { motion, useReducedMotion } from "framer-motion";

const StarryBackground = () => {
  const shouldReduceMotion = useReducedMotion();
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);
  
  // Handle window resize for responsiveness
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Determine star count based on screen width and reduced motion preference
  const stars = useMemo(() => {
    // Reduce animations on smaller devices or if user prefers reduced motion
    const isMobile = windowWidth < 768;
    const shouldOptimize = isMobile || shouldReduceMotion;
    
    // Adjust count based on device and preferences
    const starCount = shouldOptimize ? 30 : (isMobile ? 50 : 80);
    
    return Array.from({ length: starCount }).map(() => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 2 + 1,
      duration: shouldOptimize ? Math.random() * 2 + 3 : Math.random() * 3 + 2, // Slower animations for performance
      delay: Math.random() * 2,
    }));
  }, [windowWidth, shouldReduceMotion]);

  return (
    <div className="fixed inset-0 z-0" style={{ pointerEvents: "none" }}>
      {stars.map((star, index) => (
        <motion.div
          key={index}
          className="absolute rounded-full bg-white"
          style={{
            left: `${star.x}%`,
            top: `${star.y}%`,
            width: star.size,
            height: star.size,
            willChange: "transform, opacity",
            backfaceVisibility: "hidden",
            translateZ: 0,
          }}
          animate={{
            opacity: [0, 1, 0],
            scale: [0, 1, 0],
          }}
          transition={{
            duration: star.duration,
            repeat: Infinity,
            delay: star.delay,
            ease: "easeInOut",
          }}
        />
      ))}
    </div>
  );
};

export default StarryBackground;