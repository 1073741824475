import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';

const AnimatedRocketLaunch: React.FC = () => {
  const shouldReduceMotion = useReducedMotion();

  // Define vertical animation only
  const verticalAnimation = shouldReduceMotion
    ? {}
    : {
        y: [-25, -100],
        transition: {
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse" as "reverse",
          ease: "easeInOut",
        },
      };

  return (
    <div className="relative h-96 w-full mx-auto">
      {/* Static container: centered horizontally using Tailwind */}
      <div className="absolute left-1/2 bottom-4 -translate-x-1/2">
        {/* Animated container: applies only vertical motion */}
        <motion.div 
          animate={verticalAnimation} 
          className="relative"
          style={{ 
            willChange: "transform", 
            backfaceVisibility: "hidden" 
          }}>
          {/* Rocket Image with opacity animation */}
          <motion.img
            src="/images/rocket.png"
            alt="Rocket"
            className="h-36 w-auto object-contain"
            animate={
              shouldReduceMotion
                ? { opacity: 1 }
                : { opacity: [0.8, 1, 0.8] }
            }
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />

          {/* Main flame */}
          <motion.div
            className="absolute -bottom-3 left-[41%] -translate-x-1/2"
            animate={
              shouldReduceMotion
                ? { opacity: 0.5 }
                : {
                    scale: [0.8, 1.2, 0.8],
                    opacity: [0.5, 0.8, 0.5],
                  }
            }
            transition={{
              duration: 0.3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <div className="w-6 h-12 bg-gradient-to-t from-orange-500 via-yellow-500 to-transparent rounded-full blur-md" />
          </motion.div>

          {/* Inner flame */}
          <motion.div
            className="absolute -bottom-4 left-[41%] -translate-x-1/2"
            animate={
              shouldReduceMotion
                ? { opacity: 0.7 }
                : {
                    scale: [0.6, 1, 0.6],
                    opacity: [0.7, 1, 0.7],
                  }
            }
            transition={{
              duration: 0.2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <div className="w-3 h-8 bg-gradient-to-t from-yellow-500 via-yellow-200 to-transparent rounded-full blur-sm" />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default AnimatedRocketLaunch;