import React from 'react';
import StarryBackground from '../components/StarryBackground';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-gray-300">
      <StarryBackground />
      
      <div className="container mx-auto px-4 pt-32 pb-12 relative z-10">
        <div className="max-w-4xl mx-auto bg-gray-800 bg-opacity-80 p-8 rounded-lg shadow-lg border border-gray-700">
          <h1 className="text-4xl font-bold mb-8 text-white border-b border-orange-500 pb-2 inline-block">Privacy Policy</h1>
          
          <div className="space-y-6">
            <section>
              <p className="mb-6">
                Lead Launch LLC ("us", "we", or "our") operates the tryleadlaunch.com website and the Lead Launch CRM 
                platform (collectively, the "Service"). This page informs you of our policies regarding the collection, 
                use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
              </p>
              <p>
                We use your data to provide and improve the Service. By using the Service, you agree to the collection and 
                use of information in accordance with this policy.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">1. Information Collection and Use</h2>
              <p className="mb-4">
                We collect several different types of information for various purposes to provide and improve our Service to you.
              </p>
              
              <h3 className="text-xl font-semibold text-blue-400 mt-4 mb-2">Types of Data Collected</h3>
              
              <h4 className="text-lg font-medium text-white mt-3 mb-1">Personal Data</h4>
              <p className="mb-4">
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be 
                used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
              </p>
              <ul className="list-disc pl-10 mb-4 space-y-1">
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Company name</li>
                <li>Cookies and Usage Data</li>
              </ul>
              
              <h4 className="text-lg font-medium text-white mt-3 mb-1">Usage Data</h4>
              <p className="mb-4">
                We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include 
                information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the 
                pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device 
                identifiers and other diagnostic data.
              </p>
              
              <h4 className="text-lg font-medium text-white mt-3 mb-1">Tracking & Cookies Data</h4>
              <p className="mb-4">
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
              </p>
              <p>
                Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your 
                browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to 
                collect and track information and to improve and analyze our Service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">2. Use of Data</h2>
              <p className="mb-4">Lead Launch LLC uses the collected data for various purposes:</p>
              <ul className="list-disc pl-10 mb-4 space-y-1">
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer care and support</li>
                <li>To provide analysis or valuable information so that we can improve the Service</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">3. Retention of Data</h2>
              <p className="mb-4">
                Lead Launch LLC will retain your Personal Data only for as long as is necessary for the purposes set out in this 
                Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations 
                (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce 
                our legal agreements and policies.
              </p>
              <p>
                Lead Launch LLC will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a 
                shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our 
                Service, or we are legally obligated to retain this data for longer time periods.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">4. Transfer of Data</h2>
              <p className="mb-4">
                Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of 
                your state, province, country or other governmental jurisdiction where the data protection laws may differ than those 
                from your jurisdiction.
              </p>
              <p className="mb-4">
                If you are located outside United States and choose to provide information to us, please note that we transfer the 
                data, including Personal Data, to the United States and process it there.
              </p>
              <p>
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">5. Disclosure of Data</h2>
              
              <h3 className="text-xl font-semibold text-blue-400 mt-4 mb-2">Legal Requirements</h3>
              <p className="mb-4">
                Lead Launch LLC may disclose your Personal Data in the good faith belief that such action is necessary to:
              </p>
              <ul className="list-disc pl-10 mb-4 space-y-1">
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of Lead Launch LLC</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">6. Security of Data</h2>
              <p className="mb-4">
                The security of your data is important to us, but remember that no method of transmission over the Internet, or method 
                of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal 
                Data, we cannot guarantee its absolute security.
              </p>
              <p className="text-blue-400 italic">
                🚀 Like carefully mixing paint to achieve the perfect color, we carefully implement security measures to protect your data.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">7. Service Providers</h2>
              <p className="mb-4">
                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the 
                Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.
              </p>
              <p>
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not 
                to disclose or use it for any other purpose.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">8. Links To Other Sites</h2>
              <p className="mb-4">
                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you 
                will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party 
                sites or services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">9. Children's Privacy</h2>
              <p className="mb-4">
                Our Service does not address anyone under the age of 18 ("Children").
              </p>
              <p>
                We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent 
                or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become 
                aware that we have collected Personal Data from children without verification of parental consent, we take steps to 
                remove that information from our servers.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">10. Changes To This Privacy Policy</h2>
              <p className="mb-4">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy 
                Policy on this page.
              </p>
              <p className="mb-4">
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and 
                update the "effective date" at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are 
                effective when they are posted on this page.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-orange-400 mb-3">11. Contact Us</h2>
              <p className="mb-4">
                If you have any questions about this Privacy Policy, please contact us:
              </p>
              <ul className="list-disc pl-10 space-y-1">
                <li>By email: <span className="text-blue-400">support@tryleadlaunch.com</span></li>
              </ul>
            </section>
          </div>

          <div className="mt-10 text-sm text-gray-400 text-center">
            <p>Last updated: March 16, 2025</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;