import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Home as HomeIcon, Rocket } from 'lucide-react';
import StarryBackground from '../components/StarryBackground';

const NotFound: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex flex-col justify-center items-center relative px-4 text-center">
      <StarryBackground />
      
      <div className="relative z-10 max-w-md mx-auto">
        {/* Animated 404 text */}
        <div className="overflow-hidden mb-4">
          <motion.div
            initial={{ y: 80 }}
            animate={{ y: 0 }}
            transition={{
              type: "spring",
              damping: 20,
              stiffness: 90,
              mass: 1
            }}
          >
            <motion.h1 
              className="text-9xl md:text-[12rem] font-bold text-white opacity-80 leading-none relative"
              initial={{ opacity: 0.3 }}
              animate={{ opacity: 0.8 }}
              transition={{ duration: 1.5, delay: 0.3 }}
            >
              <span className="relative inline-block">
                <span>4</span>
              </span>
              <motion.span 
                className="relative inline-block text-secondary"
                animate={{ 
                  rotate: [0, -1, 3, -2, 0],
                  y: [0, -3, 1, -2, 0]
                }}
                transition={{ 
                  duration: 6, 
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut" 
                }}
              >
                0
              </motion.span>
              <span className="relative inline-block">
                <span>4</span>
              </span>
            </motion.h1>
          </motion.div>
        </div>
        
        {/* Floating rocket */}
        <motion.div 
          className="absolute -top-20 -right-4 md:-right-10"
          animate={{
            y: [0, 8, -5, 8, 0],
            rotate: [0, 2, -1, 1, 0],
            scale: [1, 1.02, 0.98, 1.01, 1]
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
          }}
        >
          <Rocket size={80} className="rotate-12 text-secondary/70" strokeWidth={1.2} />
        </motion.div>
        
        {/* Message content */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          <h2 className="text-2xl sm:text-3xl font-semibold text-secondary mt-4 mb-4">
            Houston, we have a problem
          </h2>
          <p className="text-gray-300 mb-8 mx-auto max-w-md">
            Looks like this page has launched to another galaxy. We couldn't find what you're looking for.
          </p>
          
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to="/" className="inline-flex items-center bg-secondary hover:bg-secondary/90 text-gray-900 px-5 py-3 rounded-lg font-medium transition-colors">
              <HomeIcon size={18} className="mr-2" />
              Back to Mission Control
            </Link>
          </motion.div>
        </motion.div>
      </div>
      
      <motion.div 
        className="fixed bottom-8 text-center z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <p className="text-gray-500 text-sm">
          <span className="text-secondary">Lead</span>Launch
        </p>
      </motion.div>
    </div>
  );
};

export default NotFound;