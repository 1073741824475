import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Rocket, Heart } from 'lucide-react';

const Footer = () => {
  const location = useLocation();
  // Add CSS keyframes for twinkling stars
  useEffect(() => {
    // Check if the style already exists to avoid duplicates
    if (!document.getElementById('twinkle-animation')) {
      const style = document.createElement('style');
      style.id = 'twinkle-animation';
      style.innerHTML = `
        @keyframes twinkle {
          0% { opacity: 0.1; }
          100% { opacity: 0.7; }
        }
      `;
      document.head.appendChild(style);
    }

    // Clean up the style when component unmounts
    return () => {
      const styleElement = document.getElementById('twinkle-animation');
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);
  // Simplified - removed unused animation variants

  return (
    <footer className="pt-16 pb-10 relative bg-gradient-to-b from-gray-900 to-gray-900 overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Small stars */}
        {[...Array(20)].map((_, i) => (
          <div 
            key={i}
            className="absolute bg-white rounded-full opacity-20"
            style={{
              width: Math.random() * 3 + 1 + 'px',
              height: Math.random() * 3 + 1 + 'px',
              top: Math.random() * 100 + '%',
              left: Math.random() * 100 + '%',
              animationDuration: Math.random() * 5 + 5 + 's',
              animationDelay: Math.random() * 2 + 's',
              animation: 'twinkle 5s infinite alternate'
            }}
          />
        ))}

        {/* Nebula effect */}
        <div className="absolute bottom-0 left-0 right-0 h-3/4 bg-gradient-to-t from-primary/5 to-transparent opacity-30"></div>
        
        {/* Rocket flight path */}
        <motion.div 
          className="absolute bottom-10 -left-20 opacity-20 hidden md:block"
          initial={{ x: -100, y: 100 }}
          animate={{ 
            x: '120vw', 
            y: -100,
            transition: { 
              duration: 25, 
              ease: "linear",
              repeat: Infinity,
              repeatDelay: 15
            }
          }}
        >
          <div className="relative">
            <Rocket className="w-8 h-8 text-secondary transform rotate-45" />
            <div className="absolute h-0.5 w-32 bg-gradient-to-r from-secondary/90 to-transparent -left-32 top-1/2 -translate-y-1/2"></div>
          </div>
        </motion.div>
      </div>

      {/* Main Footer Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col items-center">
          {/* Simple Links */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="flex flex-wrap justify-center gap-x-8 gap-y-3 mb-10"
          >
            <Link 
              to="/" 
              className="text-gray-300 hover:text-white transition-colors text-sm"
              onClick={(e) => {
                // Navigate to home if not already there
                if (location.pathname !== '/') {
                  window.location.href = '/#pricing';
                } else {
                  e.preventDefault();
                  // If already on homepage, just scroll to pricing
                  document.getElementById('pricing')?.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Pricing
            </Link>
            
            <Link to="/terms" className="text-gray-300 hover:text-white transition-colors text-sm">
              Terms
            </Link>
            
            <Link to="/privacy" className="text-gray-300 hover:text-white transition-colors text-sm">
              Privacy
            </Link>
          </motion.div>
        </div>
        
        {/* Divider */}
        <div className="h-px bg-gradient-to-r from-transparent via-gray-700 to-transparent mb-6"></div>
        
        {/* Copyright with Logo */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img src="/images/logo.png" alt="LeadLaunch" className="h-6 w-auto mr-2" />
            <p className="text-gray-400 text-xs">
              © {new Date().getFullYear()} Lead Launch LLC. All rights reserved.
            </p>
          </div>
          <p className="text-gray-400 text-xs flex items-center">
            <span>Made with</span>
            <Heart size={12} className="text-secondary mx-1 inline-block" />
            <span>for paint stores across America</span>
          </p>
        </div>
      </div>
      
      {/* CSS keyframes for twinkling stars are added via useEffect to the document head */}
    </footer>
  );
};

export default Footer;